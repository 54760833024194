<template>
  <div class="Faq">
    <div class="banner">
      <div class="container">
      </div>
    </div>
    <div class="cont pd40">
      <div class="container">
        <div class="headTit">
          <h3><span>Feedback</span></h3>
        </div>
        <div class="jieshao">
          ZHUXUE intends to establish an open community for our members, small bits of your feedback, large steps of our growth. Please kindly leave your message below.
        </div>
        <div class="msgs">
          <div class="lis"
               v-for="(item,index) in list"
               :key="index">
            <el-row :gutter="20">
              <el-col :span="3">
                <div class="imgbox">
                  <img :src="item.thumb"
                       alt="">
                </div>
              </el-col>
              <el-col :span="21">
                <div class="text">
                  <h3>
                    {{ item.cont}}
                  </h3>
                  <p>
                    {{ item.title}}
                  </p>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="Formbox">
          <h3>Message Feedback</h3>
          <el-form ref="form"
                   :model="form"
                   :rules="rules"
                   label-width="100px">
            <el-row :gutter="20">

              <el-col :span="8">
                <el-form-item label="Your name"
                              prop="cert_name">
                  <el-input v-model="form.cert_name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Phone"
                              prop="phone">
                  <el-input v-model="form.phone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Country"
                              prop="country">
                  <el-input v-model="form.country"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Message"
                              prop="content">
                  <el-input v-model="form.content"
                            type="textarea"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-button type="primary"
                           @click="onSubmit('form')">Submit</el-button>
              </el-col>
            </el-row>
          </el-form>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";
import { ElMessage } from 'element-plus'

export default {
  data () {
    return {
      list: [
        {
          thumb: require('@/assets/feedback1.png'),
          title: 'Athena (Malaysia)',
          cont: '“ZHUXUE is a really useful platform for planning your education in China, I successfully applied a Chinese University in Beijing, and my life here is quite nice. People here are friendly and hospitable, you won’t feel isolated here. I enjoy my study and life in China.”',
        },
        {
          thumb: require('@/assets/feedback2.png'),
          title: 'Hedy (Brunie)',
          cont: '“Study in China is definitely a great choice that you’ll never regret. Thanks to ZHUXUE, I’ve settled in Shanghai for 5 years now, what can always amaze me is that the cashless society in China, which is far more convenient than my life back to my country.”',
        },
        {
          thumb: require('@/assets/feedback3.png'),
          title: 'Marques (Iran)',
          cont: '“Hello, all. I’m Harris, and I’m from Iran. I am studying MBBS in one of the China Universities in Chongqing. You may have never heard of the city, but I would love to recommend you to come over and have a look. What an amazing place for you guys to study and live, thanks ZHUXUE.”',
        },
        {
          thumb: require('@/assets/feedback4.png'),
          title: 'Tracy (Thailand)',
          cont: '“Absolutely brilliant study and life experience in Qingdao, wanna come? Join us soon!”',
        },
        {
          thumb: require('@/assets/feedback5.png'),
          title: 'Leon (Russia)',
          cont: '“Getting bored of studying in your own country? Why not come to China. I’ve been staying here for more than 6 years, and I’ve witnessed the tremendous growth of this country. There are loads of opportunities everywhere not only in big cities like Beijing and Shanghai but also other cities. China is really a big country and has numerous interesting for you to discover. ZHUXUE helped me a lot while I was studying in China, and now I quite adapted and keen to live in China.”',
        },
        {
          thumb: require('@/assets/feedback6.png'),
          title: 'Louise (Lao)',
          cont: '“My life has been entirely changed since I’ve studied in China. I’ve experienced high tech and well-constructed infrastructure, and I wish I could use what I’ve learned and seen to contribute my own country in the future.”',
        },
      ],
      form: {
        cert_name: '',
        phone: '',
        country: '',
        content: ''
      },
      rules: {
        cert_name: [
          {
            required: true,
            message: 'Please input Id name',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 64,
            message: 'Length should be 3 to 64',
            trigger: 'blur',
          },
        ],
        country: [
          {
            required: true,
            message: 'Please select Nationality',
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message: 'Please input Phone number',
            trigger: 'blur',
          },
        ],
        content: [
          {
            required: true,
            message: 'Please input message',
            trigger: 'blur',
          },
        ],
      }
    }
  },
  beforeMount () {

  },
  methods: {
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axiosApi('message', {
            form_type: 1,
            ...this.form
          }, 'post').then(res => {
            if (res.data.status) {
              ElMessage.success('success')
              this.$refs[formName].resetFields()
            } else {
              ElMessage.error('error')
            }
          })
        } else {
          return false
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner1.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.jieshao {
  width: 800px;
  margin: auto;
}
.cont {
  background-color: #edf8fe;
  .msgs {
    .lis {
      border-radius: 10px;
      background-color: #fff;
      margin-top: 30px;
      padding: 20px;
      box-shadow: 0px 4px 8px rgba($color: #000000, $alpha: 0.1);
      .text {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      h3 {
        width: 100%;
        font-weight: normal;
        font-size: 14px;
        line-height: 2;
      }
      p {
        width: 100%;
        text-align: right;
        margin-right: 0;
        margin-left: auto;
        font-weight: bold;
      }
    }
  }
  .Formbox {
    background-color: #174166;
    color: #fff;
    margin-top: 40px;
    padding: 40px;
    h3 {
      text-align: center;
      margin-bottom: 30px;
    }
  }
}

.el-form ::v-deep {
  .el-form-item__label {
    color: #fff;
  }
  .el-button {
    display: block;
    margin: auto;
  }
}
</style>